<template>
  <div class="login-page">
    <Modal shown unclosable>
      <h2 slot="header" class="offset-3 col">{{ $t("auth.loginSystem") }}</h2>
      <form slot="body" @submit.prevent="submitForm()">
        <div class="row pt-4 py-3">
          <div class="col-3 align-self-center form-label">
            {{ $t("auth.loginEmail") }}
          </div>
          <div class="col-9">
            <input
              type="text"
              autocomplete="username"
              :class="{ 'form-input--is-invalid': $v.login.$error }"
              v-model="login"
              class="form-input"
            />
          </div>
        </div>
        <div class="row py-3">
          <div class="col-3 align-self-center form-label">
            {{ $t("auth.password") }}
          </div>
          <div class="col-9">
            <input
              type="password"
              autocomplete="current-password"
              :class="{ 'form-input--is-invalid': $v.password.$error }"
              v-model="password"
              class="form-input"
            />
          </div>
        </div>
        <div class="row py-3">
          <div class="offset-3 col-9">
            <div class="d-flex">
              <button type="submit" class="form-btn col-6">
                {{ $t("auth.enter") }}
              </button>
              <router-link
              v-if="isVisible"
                :to="{
                  name: 'password-reset',
                  query: $route.query,
                }"
                class="col-6 align-self-center d-inline-block modal-link"
              >
                {{ $t("auth.recoveryPassword") }}
              </router-link>
            </div>
          </div>
        </div>
        <div class="row pt-3 pb-3">
          <section class="col offset-3 form-error-list">
            <div
              v-if="
                (this.submitted && formErr.hasOwnProperty('login')) ||
                formErr.hasOwnProperty('password')
              "
            >
              {{ $t("validations.errorLoginOrPassword") }}
            </div>
            <div
              v-if="$v.$error && (!$v.login.required || !$v.password.required)"
            >
              {{ $t("validations.enterCredentials") }}
            </div>
          </section>
        </div>
      </form>
      <div v-if="isFooterVisible" slot="footer" class="offset-3 col">
        {{ $t("auth.noAccount") }}
        <router-link
          :to="{ name: 'registration', query: $route.query }"
          class="col align-self-center modal-link"
        >
          {{ $t("auth.registration") }}
        </router-link>
      </div>
    </Modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';
import Modal from '@/components/Modal.vue';

export default {
  name: 'Login',
  components: {
    Modal,
  },
  data() {
    return {
      submitted: false,
      login: '',
      password: '',
      formErr: {},
    };
  },
  validations: {
    login: {
      required,
    },
    password: {
      required,
    },
  },
  computed: {
    isFooterVisible() {
      return this.isVisible && !(+process.env.VUE_APP_HIDE_LOGIN_FOOTER);
    },
    isVisible() {
      return process.env.VUE_APP_MODE_NAME !== 'jnow';
    },
  },
  methods: {
    submitForm() {
      this.submitted = true;
      this.formErr = {};

      this.$v.$touch();

      if (this.$v.$invalid) return;

      const loading = this.$loading({
        lock: true,
        background: 'rgba(255, 255, 255, 0.7)',
      });

      const { login, password } = this;

      this.$store
        .dispatch('authRequest', {
          action: 'token',
          user: { login, password },
        })
        .then(() => {
          this.$router.push('/');
        })
        .catch((error) => {
          if (error.data) this.formErr = error.data.error.message;
        })
        .finally(() => {
          loading.close();
        });
    },
  },
};
</script>
